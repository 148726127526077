@use '@angular/material' as mat;

/*

  STRUCTURE DES STYLES :

  => styles.scss        : Elements transversaux de l'application
  => app.component.scss : Layout de l'application
  => "autres scss"      : Style spécifique à une Way Card (étape)

  PS : Le menu debug est à droite : il suffit de le survoler pour le révéler :)

*/

/*

  THEME

*/

@include mat.core();

$md-way-orange: (
    50 : #fdefe0,
    100 : #fbd8b3,
    200 : #f8be80,
    300 : #f5a44d,
    400 : #f29126,
    500 : #f07d00,
    600 : #ee7500,
    700 : #ec6a00,
    800 : #e96000,
    900 : #e54d00,
    A100 : #ffffff,
    A200 : #ffe3d9,
    A400 : #ffbea6,
    A700 : #ffab8c,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-way-purple: (
    50 : #f4e5ee,
    100 : #e4bed5,
    200 : #d393ba,
    300 : #c1689e,
    400 : #b34789,
    500 : #a62774,
    600 : #9e236c,
    700 : #951d61,
    800 : #8b1757,
    900 : #7b0e44,
    A100 : #ffadd2,
    A200 : #ff7ab6,
    A400 : #ff479b,
    A700 : #ff2d8d,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$my-primary: mat.define-palette($md-way-orange);
$my-accent: mat.define-palette($md-way-purple);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
 ),
 typography: mat.define-typography-config(),
 density: 0,
));

@include mat.all-component-themes($my-theme);


/*

  VARIABLES

*/

:root {

  /* Color palette */
  --tds-color-bg: #F2F2F2;
  --tds-color-text: #002F5A;
  --tds-color-primary: #F07D00;
  --tds-color-accent: #A62774;
  --tds-color-danger: #E03F2A;
  --tds-color-white: #FFFFFF;
  --tds-color-grey: #808080;
  --tds-color-greylight: #C8C8C8;

  /* MSH Gradient */
  --tds-gradient-msh: linear-gradient(to right, #002F5A 20%, #004687 40%, #833D59, 60%, #D84E38 80%, #F07D00 100%);

  /* Shadows */
  --tds-shadow: 0 0 20px rgba(0,0,0,.25);
  --tds-shadow50: 0 0 10px rgba(0,0,0,.25);

  --tds-bullet: no-repeat var(--tds-color-danger) url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none'%3E%3Cpath fill='%23fff' d='M.845 9.698a.807.807 0 0 1-.528-.21C.106 9.38 0 9.17 0 8.852c0-.211.106-.422.211-.633L3.17 4.84.211 1.352C-.106 1.036 0 .508.317.191c.422-.317.95-.21 1.268.106l3.38 4.014a.829.829 0 0 1 0 1.162l-3.38 4.014c-.212.106-.423.211-.74.211Z'/%3E%3C/svg%3E");
}



#way {
  background: top center no-repeat url('../../way/src/assets/bg.svg');
  background-size: 10000px;
  background-position: calc(50% + 2300px) -620px;
}

@media only screen and (max-device-width: 480px) and (orientation: portrait) {
  #way {
    background-size: 5000px;
    background-position: calc(50% + 1120px) -80px;
  }
}

@import "../../core/src/lib/components/tds/shared-styles";

