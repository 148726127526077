/**
Styles globaux ajoutés inclus styles.scss pour les popups d'erreur
 */

/* *** Popup d'erreur *** */
.popupErreurPayement {
  border-radius: 10px;
  overflow: hidden;
  max-width: 480px;
  width: calc(100% - 40px);
  box-shadow: var(--tds-shadow);
}
