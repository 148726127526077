/*

  VARIABLES

*/


/*

  GLOBAL

  Surchage des style du navigateur

*/

* {
  box-sizing: border-box;
}

html {
  height: 100%;

  body {
    min-height: 100%;
  }
}


a {
  color: var(--tds-color-text);
}

body {
  margin: 0;
  color: var(--tds-color-text);
  font-size: 16px;
  line-height: 140%;
  height: 100%;
}


/*

  HELPERS

  Class pour faciliter les mises en formes spécifiques

*/

/* hide / show */
.tds-only-desk {
  display: block;
}

.tds-only-mobile {
  display: none;
}

@media only screen and (max-device-width: 480px) and (orientation: portrait) {
  .tds-only-desk {
    display: none;
  }
  .tds-only-mobile {
    display: block;
  }
}

/* Margins */
.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}


/*

  BUTTONS

  Apparence par défaut des principaux type de boutons

  /!\   J'ai du surcharger des class spécifique à Material Design pour être conformat à la maquette :
        Il sera nécessaire de s'assure de mettre à jour leur nom si les class change dans les prochianes verions

*/

// Buttons primary (Flat)
.mdc-button.mdc-button--unelevated,
.mdc-button.mdc-button--unelevated:disabled {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  min-width: 300px;
  min-height: 60px;
  border-radius: 10px;
  color: var(--tds-color-white);
  font-weight: bold;
  background-color: var(--tds-color-greylight);
  font-size: 16px;

  &:not(:disabled) {
    color: var(--tds-color-white);
    background-color: var(--tds-color-primary);
  }
}

@media only screen and (max-device-width: 480px) and (orientation: portrait) {
  .mdc-button.mdc-button--unelevated,
  .mdc-button.mdc-button--unelevated:disabled {
    width: 100%;
  }
}

// Buttons stroked
.mdc-button.mdc-button--outlined,
.mdc-button.mdc-button--outlined:disabled {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  min-width: 300px;
  min-height: 60px;
  border-radius: 10px;
  border: 2px solid var(--tds-color-greylight);
  background-color: var(--tds-color-white);
  color: var(--tds-color-greylight);
  font-weight: bold;
  font-size: 16px;

  &:not(:disabled) {
    border: 2px solid var(--tds-color-primary);
    color: var(--tds-color-primary);
  }
}

// Buttons Toggle
.mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
  color: var(--tds-color-text);
  background-color: transparent;
  border-radius: 10px;
  border: 3px solid var(--tds-color-greylight);
  padding: auto 10px;

  &.mat-button-toggle-checked {
    border-color: var(--tds-color-accent);
  }
}

// Bouttons rond -/+
:root {
  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    &.mat-accent {
      background-color: var(--tds-color-white);
      box-shadow: inset 0 0 0 2px var(--tds-color-text);
      color: var(--tds-color-text);
    }

    &[disabled] {
      box-shadow: inset 0 0 0 2px var(--tds-color-greylight);
      color: var(--tds-color-greylight);
    }

    &:active,
    &:focus {
      box-shadow: inset 0 0 0 2px var(--tds-color-accent);
    }
  }

  .mdc-fab:not(.mdc-ripple-upgraded):focus {
    box-shadow: inset 0 0 0 2px var(--tds-color-accent);
  }
}


/*

  FORMS

  Desktop :
  - le contenur tds-form permet de dimensionner les mat-form-field en fonction d'une grille de 12 colonnes.
  - par défaut un mat-form-field occupera moitié de la largeur (équivalent à .col-6)

  Mobile :
  - par défaut les mat-form-field occupe 100% de la largeur disponible (équivalent .col-12)
  - il es possible de lui demander d'occuper la moitier avec .m-col-6

*/

.tds-form {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 0px;
  margin-bottom: -20px;

  > * {
    grid-column: span 6;
  }

  @for $i from 1 through 12 {
    .col-#{$i} {
      grid-column: span $i;
    }
  }
}

/* FORMS (Mobile) */

@media only screen and (max-device-width: 480px) and (orientation: portrait) {
  .tds-form {
    [class*="col-"]:not([class*="m-col-"]) {
      grid-column: span 12;
    }

    @for $i from 1 through 12 {
      .m-col-#{$i} {
        grid-column: span $i;
      }
    }
  }
}


/*

  INPUT

    /!\   J'ai du surcharger des class spécifique à Material Design pour être conformat à la maquette :
          Il sera nécessaire de s'assure de mettre à jour leur nom si les class change dans les prochianes verions

*/

:root {
  .mdc-text-field--filled .mdc-floating-label {
    letter-spacing: 0;
  }

  .mat-mdc-form-field {
    .mdc-text-field {
      border: 1px solid var(--tds-color-text);
      border-radius: 3px;
      background-color: var(--tds-color-white);

      .mdc-floating-label {
        color: var(--tds-color-text);
      }

      .mat-mdc-form-field-focus-overlay {
        background-color: var(--tds-color-white);
      }

      .mat-datepicker-toggle {
        color: var(--tds-color-text);
      }

      .paysInput {
        margin-left: 5px;
      }

      .mdc-line-ripple {
        &::after {
          content: none;
        }

        &::before {
          content: none;
        }
      }
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
      color: var(--tds-color-text);
    }

    // Input en erreur
    &.status-invalide.interaction-not-pristine {
      .mdc-text-field {
        border-color: var(--tds-color-danger);
        box-shadow: 0 0 0 1px var(--tds-color-danger);
      }
    }

    .mdc-text-field--filled:not(-mdc-text-field--disabled).mdc-text-field--focused {
      .mdc-floating-label {
        color: var(--tds-color-text);
      }
    }
  }
}

// Position menu déroulant
.choixIndicatifsPanel {
  margin-left: -35px;
  min-width: 130px;
}

.choixPaysPanel {
  margin-left: -35px;
  width: calc(100% + 35px);
}

// Checkbox
.mat-mdc-checkbox .mdc-form-field {
  color: var(--tds-color-text);
}

// Radio
.mat-mdc-radio-button .mdc-form-field {
  color: var(--tds-color-text);
}

// Style pour les champs en erreurs
.status-invalide.interaction-not-pristine {
  .mat-mdc-text-field-wrapper {
    border-color: var(--tds-color-danger);
  }
}

.country-flag {
  //Permet de rajouter les flags emoji sur Windows >7 qui ne les supporte pas par défaut
  font-family: "Twemoji Country Flags", "Roboto", "Helvetica Neue", sans-serif;

  + input {
    margin-left: 5px;
  }
}


/*

  *** tds CARD ***

  La tds CARD :
  - Coeur de l'affichage du contenu de l'application
  - Sa structure doit impérativement être respecté pour s'afficher correctemen sur desktop et mobile
    - tds-card-header : s'affiche en haut de page (dans le conteneur blanc sur desktop / en dehors du conteneur blanc sur mobile)
    - tds-card-content : le contenu principal
    - tds-card-footer : s'afficher toujours en pied de page (dans le conteneur blanc sur desktop / en dehors du conteneur blanc sur mobile)

  - Structure d'une TDS CARD :

  <div class="tds-card">
    <div class="tds-card-header">
        <div class="tds-card-header-title">
          <a>[LIEN RETOUR - optionel]</a>
          <h2>[TITRE DE L'ECRAN]</h2>
        </div>
    </div>
    <div class="tds-card-content">
        <div class="tds-card-content-ct">
            <div class="tds-subcards">
                <div class="tds-subcards-item noborder">
                  [CONTENU - "noborder" permet masquer le liseré gris sur desktop]
                </div>
            </div>
        </div>
    </div>
    <div class="tds-card-footer">
      [<button>
        GENERALEMENT UN CALL TO ACTION]
      </button>]
    </div>
  </div>

*/

.tds-card {
  box-shadow: var(--tds-shadow);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 430px;
  width: 633px;
  background-color: var(--tds-color-white);
  margin: 20px;

  &-header {
    &-title {
      display: flex;

      a {
        margin-right: 10px;
        color: inherit;

        mat-icon {
          font-size: 32px;
          width: 32px;
          height: 32px;
        }
      }

      h2 {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 16px;
      }
    }
  }

  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    &-ct {
      flex: 1;
    }

    .tds-subcards {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      &-item {
        width: 100%;
        border: 1px solid var(--tds-color-greylight);
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 20px;

        &.noborder {
          border: none;
          border-radius: 0;
          padding: 0;
        }

        p:last-child:not([class*="mb-"]) {
          margin-bottom: 0;
        }

        &.compact{
          h3{
            margin-bottom:0;
          }
        }
      }

      h3 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 16px;
      }
    }

  }

  &-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: flex-end;

  }
}

/* *** TDS CARD *** (MOBILE) */

@media only screen and (max-device-width: 480px) and (orientation: portrait) {
  .tds-card {
    width: calc(100vw - 40px);
    min-height: auto;
    box-shadow: none;
    background-color: transparent;
    padding: 0;

    &-header {
      &-title {
        color: var(--tds-color-white);

        h2 {
          font-size: 24px;
        }
      }
    }

    &-content {
      h4 {
        color: var(--tds-color-white);
      }

      &-ct {
        .tds-subcards {
          display: flex;

          &-item {
            background-color: var(--tds-color-white);
            box-shadow: var(--tds-shadow);
            border-radius: 10px;
            padding: 20px;
            width: 100%;
            border: none;

            &.noborder {
              border: none;
              border-radius: 10px;
              padding: 20px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &-footer {
      margin-top: 30px;

      button {
        width: 100%;
      }
    }
  }
}

@import './erreur-popup/erreur-popup.component.global';

mat-list-option.hideSelectbox {

  .mdc-checkbox {
    display: none !important;
  }

}

.bg-primary {
  background-color: var(--tds-color-primary) !important;
}

.bg-accent {
  background-color: var(--tds-color-accent) !important;
}

.border-primary {
  border: var(--tds-color-primary) 2px solid !important;
}

.border-accent {
  border: var(--tds-color-accent) 2px solid !important;
}

.mat-chip-set-centered > * {
  justify-content: center !important;
}

.mat-chip-set-inverse {
  mat-chip {
    --mdc-chip-elevated-container-color: var(--tds-color-text);
    --mdc-chip-label-text-color: var(--tds-color-bg);
  }
}

.mat-chip-set-compact {
  mat-chip {
    --mdc-chip-container-height: 22px !important;
  }
}

button.compact{
  --scaleFactor: 0.7;
  transform: scale(var(--scaleFactor));
  --mat-fab-small-foreground-color:red;
  mat-icon{
    transform: scale(calc(calc(1 / var(--scaleFactor) * 0.8)))
  }

  mat-icon[data-mat-icon-type=svg] {
    //Les icônes qui ont directement un SVG n'ont pas besoin d'être transformées
    transform: unset;
  }
}

mat-list.compact{
  padding:0px;
  mat-list-item{
    height: 24px !important;
    mat-icon,img{
      margin-left:0 !important;
      margin-right: 5px !important;
    }
  }
}
/**
Par défaut, les sliders material n'affichent la valeur au dessus que lorsque l'on clique dessus.
On souhaite l'afficher en permanence.
 */
.mdc-slider {
  .mdc-slider__value-indicator {
    transform: scale(1) !important;
  }
}
